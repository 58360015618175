import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from "../components/header"
import Text from "../components/text"
import Services from "../components/services"
import Contact from "../components/contact"
import Brands from "../components/brands"
import Products from "../components/products"
import Icons from "../components/icons"
import Heading from "../components/heading"
import TextImageAndVideo from "../components/text_image_and_video"
import Teams from "../components/teams"

const HomePage = ({data}) => {
  const entry = data.prismicHomePage
  if (!entry) return null
  return (
    <> 
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image={entry.data.meta_image.url} pathname={linkResolver(entry.data)} />
      <Header logo='cuddon' />
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'text') {
          return (
            <div key={slice.id}>
              <Text primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'services') {
          return (
            <div key={slice.id}>
              <Services primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'brands') {
          return (
            <div key={slice.id}>
              <Brands primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'contact') {
          return (
            <div key={slice.id}>
              <Contact primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'products') {
          return (
            <div key={slice.id}>
              <Products primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'icons') {
          return (
            <div key={slice.id}>
              <Icons primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'heading') {
          return (
            <div key={slice.id}>
              <Heading primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text_image_and_video') {
          return (
            <div key={slice.id}>
              <TextImageAndVideo primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'teams') {
          return (
            <div key={slice.id}>
              <Teams primary={slice.primary} />
            </div>
          )
        }
      })}
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicHomePage {
    data {
      page_title
      meta_description
      meta_image {
        url
      }
      body {
        ... on PrismicHomePageDataBodyBrands {
          id
          items {
            image1 {
              gatsbyImageData
              alt
            }
          }
          primary {
            heading1
            text1 {
              richText
            }
          }
          slice_type
        }
        ... on PrismicHomePageDataBodyContact {
          id
          slice_type
          primary {
            heading1
            text1 {
              richText
            }
            background {
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicHomePageDataBodyHeading {
          id
          slice_type
          primary {
            button1
            heading1
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
            image1 {
              gatsbyImageData
              alt
            }
            parent {
              url
              uid
              type
              link_type
            }
          }
        }
        ... on PrismicHomePageDataBodyIcons {
          id
          items {
            heading1
            image1 {
              gatsbyImageData
              alt
            }
            text1 {
              richText
            }
          }
          slice_type
        }
        ... on PrismicHomePageDataBodyProducts {
          id
          slice_type
          items {
            heading1
            image1 {
              gatsbyImageData
              alt
            }
            page_link1 {
              url
              uid
              type
            }
          }
          primary {
            tag
          }
        }
        ... on PrismicHomePageDataBodyServices {
          id
          items {
            button1
            heading1
            image1 {
              alt
              gatsbyImageData
            }
            page_link1 {
              uid
              url
              type
            }
            web_link
            text1 {
              richText
            }
          }
          slice_type
          primary {
            tag
          }
        }
        ... on PrismicHomePageDataBodyTeams {
          id
          items {
            email
            image1 {
              alt
              gatsbyImageData
            }
            name
            phone
            position
          }
          slice_type
        }
        ... on PrismicHomePageDataBodyText {
          id
          primary {
            button1
            heading1
            page_link1 {
              url
              uid
              type
            }
            text1 {
              richText
            }
            tag
          }
          slice_type
        }
        ... on PrismicHomePageDataBodyTextImageAndVideo {
          id
          slice_type
          primary {
            button_link
            button_pdf
            video
            text1 {
              richText
            }
            pdf {
              url
              uid
              type
              localFile {
                publicURL
              }
              link_type
            }
            page_link1 {
              url
              uid
              type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`

export default HomePage
